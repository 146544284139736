import React, { useEffect } from "react";
import {AButtonStyles, InstaStyles} from "./InstaStyles";
import {Link} from "gatsby";
import Button from "../Button/Button";
import {MdArrowForward as Arrow} from "react-icons/md";
const Insta = () => {

  useEffect(() => {
    const script = document.createElement("script")

    script.src = 'https://embedsocial.com/cdn/ht.js'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  })

  return (
    <InstaStyles id="insta">
      <section className={"section"}>
        <div className={"container container__tight"}>
          <h4 className={"insta-title"}>See Our Work on Instagram</h4>
          <div className="embedsocial-hashtag" data-ref="a8af4055dfe4f5df43534da77bbf3e8a6750754a"><a
            className="feed-powered-by-es feed-powered-by-es-feed-new" href="https://embedsocial.com/social-media-aggregator/"
            target="_blank" title="Widget by EmbedSocial">Widget by EmbedSocial<span>→</span></a></div>
          <div className={'insta-link'}>
            <AButtonStyles href={"https://www.instagram.com/evokeoffroad"} target={"_blank"} className={"btn"}>See more on Instagram <Arrow style={{ marginLeft: "10px" }} /></AButtonStyles>
          </div>
        </div>
      </section>
    </InstaStyles>
  );
};
export default Insta;
