import styled from "styled-components"

export const BasicTextModuleStyles = styled.section`

  .container {
    &.light {
      color: var(--background);

      p {
        color: var(--background);
      }
    }

    @media (min-width: 768px) {
    display: flex;
    grid-column-gap: 30px;
    align-items: center;

      > div {
        width: 66.666%;
        max-width: 700px;
      }
    }

    .container--image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
        width: auto;
        flex: 0 1 auto;
      }
    }
  }
`
