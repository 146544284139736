import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import Perk2 from "./Perk2"

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/abstract-building.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight" style={{ marginBottom: '120px'}}>
        <Perk2
          title="Suspension, Tires, & Wheels"
          content="We carry the major brands for tires, wheels, and lifts for your off-road vehicle."
        >
          <StaticImage
            src="../../../static/sus-tires-wheels.jpg"
            alt="Suspension Tires and Wheels"
            layout="constrained"
            placeholder="blurred"
          />
        </Perk2>
        <span className="perks__divider">
          <Cross />
        </span>
        <Perk2
          title="UTV & Side X Side"
          content="We offer products and accessories for all types of off-road equipment."
        >
          <StaticImage
            src="../../../static/side-by-side-perk.jpg"
            alt="Side by Side"
            layout="constrained"
            placeholder="blurred"
          />
        </Perk2>
      </div>
      <div className="container container__tight">
        <Perk2
          title="Tint"
          content="Need some tint? We got you. We use high quality products and the best installation team."
        >
          <StaticImage
            src="../../../static/window-tint.jpg"
            alt="Window Tint"
            layout="constrained"
            placeholder="blurred"
          />
        </Perk2>
        <span className="perks__divider">
          <Cross />
        </span>
        <Perk2
          title="Vinyl & Graphics"
          content="Hook up your vehicle with graphics or vinyl for your business."
        >
          <StaticImage
            src="../../../static/graphics.jpg"
            alt="Vehicle Graphics"
            layout="constrained"
            placeholder="blurred"
          />
        </Perk2>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
