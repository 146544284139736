import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"

const BasicTextModule = ({ title, content, link, linkText, id, img, light }) => {
  return (
    <BasicTextModuleStyles className="section" id={id}>
      <div className={`container container__tight ${light ? "light" : ""}`} >
        <div>
          {title && <h2>{title}</h2>}
          {content && <p style={{ marginBottom: "60px" }}>{content}</p>}

          <Button text={linkText} as={Link} to={link} />
        </div>
        { img &&
          <div className="container--image">
            <img src={ img } alt={ title + ' Image'} />
          </div>
        }
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
