import styled from "styled-components"

export const InstaStyles = styled.section`
  position: relative;

  .insta-title {
    position: absolute;
    top: 25px;
    z-index: 2;
  }

  .insta-link {
    position: absolute;
    bottom: 25px;
  }

  .embedsocial-hashtag {
    margin-left: -25px;
    margin-right: -25px;

    &::before {
      content: "";
      width: 100%;
      height: 170px;
      background: var(--background);
      position: absolute;
      top: -80px;
      left: 0;
      right: 0;
    }

    &::after {
      content: "";
      width: 100%;
      height: 150px;
      background: var(--background);
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
    }

    iframe {
      margin-top: -100px;
    }

    @media (min-width: 779px) {
      &::before {
        height: 100px;
        top: 0;
        left: 0;
        right: 0;
      }

      iframe {
        margin-top: 0;
      }
    }
  }
`

export const AButtonStyles = styled.a`
  color: #fff;
  background-color: transparent;
  font-family: "Heebo", sans-serif;
  border: 0;
  text-decoration: none;
  padding: 0;
  transition: color 0.3s ease;
  text-transform: capitalize;
  font-size: var(--p);
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  align-self: flex-start;
  display: inline-flex;
  align-items: center;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    left: 0;
    right: 0;
    bottom: 8px;
    background-color: var(--primary);
    transition: left 0.3s ease;
  }

  &:focus {
    color: var(--primary);
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: var(--primary);

      &::after {
        left: 100%;
      }
    }
  }
`
