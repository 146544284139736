import * as React from "react"
import { Perk2Styles } from "./PerksModuleStyles"

const Perk2 = ({ children, title, content }) => {
  return (
    <Perk2Styles>
      {children}
      <div className={"perk-content-wrapper"}>
        {title && <h3>{title}</h3>}
        {content && <p>{content}</p>}
      </div>
    </Perk2Styles>
  )
}

export default Perk2
