import * as React from "react"
import { Link, navigate } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import {useEffect, useState} from "react";

const BannerModule = ({ children, title, subTitle, price, enquire }) => {
  const [mQuery, setMQuery] = useState(false);

  function scrollToArea() {
    navigate("#topContent")
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let mediaQuery = window.matchMedia("(min-width: 980px)");
      console.log('MATCHES?', mediaQuery.matches);
      setMQuery(mediaQuery.matches);
      mediaQuery.addEventListener('change', (e) => setMQuery(e.matches));
      // this is the cleanup function to remove the listener
      return () => mediaQuery.removeEventListener(setMQuery);
    }
  }, []);

  return (
    <>
      <BannerModuleStyles>
        {children ? (
          children
        ) : (
          <>
          <StaticImage
            className={`banner__image ${mQuery ? "show-image" : "hide-image"}`}
            imgClassName="banner__image--content"
            src="../../../static/evoke-homepage.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
          <StaticImage
            className={`banner__image ${mQuery ? "hide-image" : "show-image"}`}
            imgClassName="banner__image--content"
            src="../../../static/mobile-evoke-banner.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
          </>
        )}

        <div className="container">
          <div className="banner__content">
            {title && (
              <h1>
                {title}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h1>
            )}
            {subTitle && <h2>{subTitle}</h2>}
            {price && (
              <h2 className="price">
                £{price}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h2>
            )}
            <div className="banner__btns">
              {enquire && (
                <Button
                  className="btn"
                  text="Call us for a Quote"
                  as={Link}
                  to="tel://+17605524216"
                />
              )}
              {/*<Button to={null} onClick={scrollToArea} text="Learn More" />*/}
            </div>
          </div>
        </div>
        <div className="gradient"></div>
      </BannerModuleStyles>
      <span id="topContent"></span>
    </>
  )
}

export default BannerModule
