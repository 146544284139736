import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Insta from "../components/Insta/Insta";
import TouchCarousel, {clamp} from "react-touch-carousel";
import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC'
import NonPassiveTouchTarget from '../components/NonPassiveTouchTarget/NonPassiveTouchTarget'


const Index = () => {
  const cardSize = 150;
  const cardPadCount = 3;
  const carouselWidth = clamp(getWindow().innerWidth, 0, 960);

  function getWindow() {
    return typeof window !== 'undefined' ? window : { innerWidth: 2000 };
  }

  const data = [
    { file: '/logos/fox.png', name: 'Fox Shocks'},
    { file: '/logos/icon.png', name: 'Icon Shocks'},
    { file: '/logos/king.png', name: 'King'},
    { file: '/logos/kmc.png', name: 'KMC Logo' },
    { file: '/logos/method.png', name: 'Method Race Wheels' },
    { file: '/logos/yokohama.png', name: 'Yokohama Tires' },
    { file: '/logos/ct-logo.png', name: 'CT Logo' },
    { file: '/logos/kryptonite.png', name: 'Kryptonite' },
    { file: '/logos/mopar.png', name: 'Mopar' },
    { file: '/logos/rough-country.png', name: 'Rough Country' },
  ]

  function CarouselContainer(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data.length;
    while (current < 0) {
      current += data.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2;
    return (
      <NonPassiveTouchTarget
        className={"carousel-container"}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />

        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
  }

  const Container = touchWithMouseHOC(CarouselContainer)

  function renderCard(index, modIndex) {
    const item = data[modIndex];
    return (
      <img
        src={ item.file }
        alt={ item.name }
      />
    );
  }

  return (
    <>
      <Seo title="Home" />
      <Layout>
        <div id="top-page"></div>
        <BannerModule
          title="Performance Parts & Services"
          subTitle="We are Southern California's premier off-road shop. Call us today for a quote 760.552.4216"
          enquire={true}
        />

        <BasicTextModule
          id="about"
          title="We provide top quality parts and service for your off-road vehicles"
          content="At Evoke Offroad we are committed to creating reliable cutting-edge designs and surpassing all expectations! We offer a diverse variety of leveling kits and lift kits to full long travel kits to fit your vehicle.
              It does not stop there, we also offer a wide range of Can Am, Razor, and all SxS parts.
              We only carry the best and most trusted brands in the world. At Evoke, we strive to be top notch in this ever- growing industry for all our valued clients."
          img={'/offroad-shot.webp'}
        />
        <Insta />
        <TouchCarousel
          component={Container}
          cardSize={cardSize}
          cardCount={data.length}
          cardPadCount={cardPadCount}
          loop={true}
          autoplay={4000}
          renderCard={renderCard}
        />
        <div className={"light-section"}>
          <BasicTextModule
            id="cta"
            title="Call us today 760.552.4216"
            content="We make it simple. Drop us a call and we will get back to you. Evoke Offroad offers the best products and best service around. "
            light={true}
            img={'/side-by-side.jpg'}
            linkText={"Call now"}
            link={"tel:7605524216"}
          />
        </div>
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        {/*<Features*/}
        {/*  title="Featured Products from Barcadia."*/}
        {/*  introduction="Vivamus quam mauris, pulvinar vel mauris id, interdum semper neque. Proin malesuada libero eget tellus scelerisque, id egestas tortor egestas."*/}
        {/*/>*/}
        {/*<LatestPosts*/}
        {/*  title="The Latest from Barcadia"*/}
        {/*  introduction="Cras scelerisque, tellus sed gravida tincidunt, velit tellus blandit justo, nec viverra augue erat in erat. Maecenas iaculis sed purus non fringilla."*/}
        {/*/>*/}
      </Layout>
    </>
  )
}

export default Index
